@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');

body{
    padding: 0;
    margin: 0;
    font-family: 'Open Sans', sans-serif i !important;
}

.App{
    /* background-color: #e5d3b3;
    height: 100vh;
    width: 100%;
    padding: 0;
    margin: 0; */
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
}
