.contact-container {
  height: 100vh;
  background-color: #887e6c;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  font-family: "Open Sans", sans-serif;
  padding-bottom: 100px;
}

.contact-heading {
  margin-top: 115px;
  margin-bottom: 40px;
  color: white;
}

.contact-form-container {
  width: 80%;
  height: fit-content;
  background-color: white;
}

.contact-form {
  margin: 1em;
  padding-bottom: 0.5em;
}

.submit-container {
  display: flex;
  justify-content: flex-end;
}

@media screen and (min-width: 768px) {
  .contact-form-container {
    margin-top: 8em;
  }

  .contact-form-container {
    max-width: 30em;
  }
}
